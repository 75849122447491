<template>
  <v-col :cols="cols">
    <v-select
      v-model="inputVal"
      :items="field.answers"
      :item-text="field.itemtxt"
      :item-value="field.itemid"
      :label="field.label"
      :disabled="field.disabled"
      :placeholder="field.placeholder"
      :menu-props="{ offsetY: true }"
      :clearable="field.clearable"
      outlined
      :multiple="multiple"
      dense
      :rules="field.rules === true || rules ? Empty : none"
      :value="inputVal"
      class="selected"
      v-on:change="changeEvent()"
    >
      <template #item="{ item }">
        <span class="labelStyle" v-if="field.itemtxt"> {{ item.name }}</span>
        <span class="labelStyle" v-else> {{ item }}</span>
      </template>
    </v-select>
  </v-col>
</template>
<script>
export default {
  name: "drop",
  props: ["fields", "cols", "model", "rules", "multiple"],
  data() {
    return {
      field: this.fields,
      Empty: [(v) => !!v || "Campo obrigatório"],
      none: [],
    };
  },
  methods: {
    changeEvent() {
      this.$emit("HandleChange");
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/SelectField.css";
</style>
